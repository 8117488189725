"use client"

import React from 'react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'

import { Modal, Typography, Descriptions, Divider, Select, Button } from 'antd'
import type { DescriptionsProps } from 'antd'
import toast, { Toaster } from 'react-hot-toast'

import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/redux/store'
import { postCheckCostCenter } from '@/redux/slices/awsSlice'
import { costCenterSelector } from '@/redux/slices/masterdataSlice'

import { INF_CHECK_COST_CENTER_REQ, INF_CHECK_COST_CENTER_RES, REQUESTOR_WF } from '@/models/aws.model'
import { selectList } from '@/models/pha.model'

type Props = {
  stateOepn: boolean
  onModelClose: () => void
}

const { Title } = Typography

const ModelCostCenter = ({ stateOepn, onModelClose }: Props) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const costCenterList = useSelector(costCenterSelector)
  const { data: session } = useSession()

  console.log(costCenterList)

  const [loadData, setLoadData] = React.useState<boolean>(false)
  const [loadDataBtn, setLoadDataBtn] = React.useState<boolean>(false)
  const [requestorData, setRequestorData] = React.useState<REQUESTOR_WF | undefined>(undefined)
  const [requestorDesc, setRequestorDesc] = React.useState<DescriptionsProps['items'] | undefined>(undefined)
  const [listSource, setListSource] = React.useState<selectList[] | undefined>(undefined)

  // Handle modal close
  const handleModelClose = async () => {
    onModelClose()
  }

  // Handle change Cost Center
  const handleChangeCostCenter = async (value: string) => {
    console.log(value)

    const orgName = requestorData?.cost_centers.filter(d => value == d.cost_center_name)
    console.log(orgName, orgName?.length)

    let cc = value
    let dp = orgName?.length! > 0 ? orgName![0].department : value
    let dv = orgName?.length! > 0 ? orgName![0].division : ""
    let dc = orgName?.length! > 0 ? orgName![0].comp_code : ""

    await localStorage.setItem('isz_cost_center', value)
    await localStorage.setItem('isz_department', dp)
    await localStorage.setItem('isz_division', dv)
    await localStorage.setItem('isz_comp_code', dc)


    const items: DescriptionsProps['items'] = [
      {
        key: '1',
        label: 'BU',
        children: requestorData?.bu
      }, {
        key: '2',
        label: 'Sub BU',
        children: requestorData?.sub_bu
      }, {
        key: '3',
        label: 'Busines Group',
        children: requestorData?.business_group
      }, {
        key: '4',
        label: 'Name',
        span: 2,
        children: `${requestorData?.prefix} ${requestorData?.name} ${requestorData?.lastname}`
      }, {
        key: '5',
        label: 'Email',
        children: requestorData?.email
      }, {
        key: '6',
        label: 'Comp Code',
        children: dc
      }, {
        key: '7',
        label: 'Cost Center',
        span: 2,
        children: cc,
      }, {
        key: '8',
        label: 'Department',
        span: 3,
        children: dp
      }, {
        key: '9',
        label: 'Division',
        span: 3,
        children: dv
      }
    ]

    await setRequestorDesc(items)
    router.refresh()
  }

  const handleLoadData = async () => {
    await setLoadDataBtn(true)
    console.log("load Data")

    const data: INF_CHECK_COST_CENTER_REQ = {
      requestor: session?.user?.email!
    }

    console.log(data)

    await dispatch(postCheckCostCenter(data)).then(async (res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error('Failed: Please try again later.')
      } else {
        const resData: INF_CHECK_COST_CENTER_RES = res.payload as INF_CHECK_COST_CENTER_RES
        const requestors = resData.result.data.requestors
        console.log(resData)

        if (requestors.length > 0) {
          let isz_cost_center = await localStorage.getItem("isz_cost_center") || ""
          let isz_department = await localStorage.getItem("isz_department") || ""
          let isz_division = await localStorage.getItem("isz_division") || ""
          let isz_comp_code = await localStorage.getItem("isz_comp_code") || ""

          requestors.filter(d => d.email === session?.user?.email)

          requestors.map(async d => {
            if (d.email === session?.user?.email) {
              await setRequestorData(d)

              const items: DescriptionsProps['items'] = [
                {
                  key: '1',
                  label: 'BU',
                  children: d.bu,
                }, {
                  key: '2',
                  label: 'Sub BU',
                  children: d.sub_bu,
                }, {
                  key: '3',
                  label: 'Busines Group',
                  children: d.business_group,
                }, {
                  key: '4',
                  label: 'Name',
                  span: 2,
                  children: `${d.prefix} ${d.name} ${d.lastname}`,
                }, {
                  key: '5',
                  label: 'Email',
                  children: d.email,
                }, {
                  key: '6',
                  label: 'Comp Code',
                  children: isz_comp_code,
                }, {
                  key: '7',
                  label: 'Cost Center',
                  span: 2,
                  children: isz_cost_center,
                }, {
                  key: '8',
                  label: 'Department',
                  span: 3,
                  children: isz_department,
                }, {
                  key: '9',
                  label: 'Division',
                  span: 3,
                  children: isz_division,
                }
              ]

              let costCenterName: selectList[] = []

              d.cost_centers.map(async t => {
                costCenterName.push({ "label": t.department, "value": t.cost_center_name, "key": `cost-center-${t.cost_center_name}` })
              })

              await setListSource(costCenterName)
              await setRequestorDesc(items)
            }
          })
        }
      }

      await setLoadData(true)
      await setLoadDataBtn(false)
    }).catch((err) => {
      console.log(err)
      throw err
    })
  }

  return <>
    <Modal title="Choose your Department" width={640} open={stateOepn} onCancel={handleModelClose} footer={[<Button key="close-modal" size="large" type="primary" danger onClick={handleModelClose}>Close</Button>]}>
      {loadData && <>
        <Divider />
        <Descriptions title="User Info" items={requestorDesc} />
        <Divider />
        <Title level={5}>Department</Title>

        <Select
          size='large'
          style={{ width: '100%' }}
          onChange={handleChangeCostCenter}
          options={listSource}
        />
      </>}

      {!loadData && <div className='align-center'><Button className='color-primary' size='large' loading={loadDataBtn} onClick={handleLoadData}>Load Data</Button></div>}
    </Modal>

    <Toaster />
  </>
}

export default ModelCostCenter

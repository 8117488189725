import React from 'react'

type Props = {}

const ImageLogo = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="179.722"
      height="39.234"
      viewBox="0 0 179.722 39.234"
    // style={"max-width: 100%"}
    >
      <g
        id="Group_52"
        data-name="Group 52"
        transform="translate(-114.111 -475.361)"
      >
        <rect
          id="Rectangle_150"
          data-name="Rectangle 150"
          width="6.629"
          height="31.18"
          transform="translate(114.111 475.703)"
        />
        <circle
          id="Ellipse_30"
          data-name="Ellipse 30"
          cx="4.078"
          cy="4.078"
          r="4.078"
          transform="translate(130.165 486.942)"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M534.443,500.9a9,9,0,1,1,6.9-14.783l4.875-4.084a15.525,15.525,0,1,0-.836,20.376l-4.448-4.275A8.962,8.962,0,0,1,534.443,500.9Z"
          transform="translate(-281.916 -0.477)"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M640.409,501.191v-6.629h13.344v-5.853H640.409v-5.639H655.3v-6.422h-22.43v30.938h5.917v.027h16.629v-6.422Z"
          transform="translate(-361.581 -0.775)"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M430.616,485.993l19.737,20.58a1.645,1.645,0,0,0,2.832-1.139V490.427a5.3,5.3,0,0,0-1.219-3.38l-8.453-10.193a3.643,3.643,0,0,0-5.653.054Z"
          transform="translate(-220.608 0)"
          fill="#ffc101"
        />
        <path
          id="Path_38"
          data-name="Path 38"
          d="M425.4,510.049l-.947,1.092a5.41,5.41,0,0,0-1.323,3.545v14.976a1.391,1.391,0,0,0,2.361,1l10.221-9.945Z"
          transform="translate(-215.388 -24.056)"
          fill="#eda31d"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M458.981,562.3c-1.779,1.848-.581,3.418.415,4.259a4.348,4.348,0,0,1,1.59,3,4.608,4.608,0,0,1-1.077,3.317,4.711,4.711,0,0,1-4.364,1.731,4.144,4.144,0,0,1-2.422-1.264c3.557-.68,1.36-4.133,1.36-4.133-1.674-2.407-1.6-4.159-1.1-5.31a3.715,3.715,0,0,1,3.442-2.186A4.251,4.251,0,0,1,458.981,562.3Z"
          transform="translate(-236.26 -60.064)"
          fill="#ffc101"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M342.2,476.255H328.574V507.3h6.654v-8.442H342.2a11.122,11.122,0,0,0,10.934-11.3h0A11.122,11.122,0,0,0,342.2,476.255Zm-1.429,16.957h-5.336a.158.158,0,0,1-.156-.161V482.286a.158.158,0,0,1,.156-.161h5.336a5.546,5.546,0,0,1,0,11.086Z"
          transform="translate(-149.484 -0.501)"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M235.954,481.216a6.545,6.545,0,0,0-3.57.888,2.965,2.965,0,0,0-1.394,2.683,3.12,3.12,0,0,0,1.394,2.747,21.052,21.052,0,0,0,5.937,2.049,16.334,16.334,0,0,1,6.845,3.085,7.307,7.307,0,0,1,2.3,5.852,7.753,7.753,0,0,1-2.915,6.274A11.623,11.623,0,0,1,236.9,507.2a17.961,17.961,0,0,1-12.3-4.775l3.126-3.761a14.076,14.076,0,0,0,9.3,3.888,6.345,6.345,0,0,0,3.824-1.035,3.24,3.24,0,0,0,1.415-2.747,3.084,3.084,0,0,0-1.331-2.662,14.008,14.008,0,0,0-4.585-1.732,41.025,41.025,0,0,1-4.944-1.437,11.143,11.143,0,0,1-3-1.711q-2.621-1.985-2.62-6.084a7.409,7.409,0,0,1,2.979-6.317,12,12,0,0,1,7.374-2.218,17.681,17.681,0,0,1,5.62.93,14.7,14.7,0,0,1,4.817,2.62l-2.662,3.761a10.07,10.07,0,0,0-3.549-1.944A13.678,13.678,0,0,0,235.954,481.216Z"
          transform="translate(-77.019 -0.749)"
          stroke="#000"
          // stroke-miterlimit="10"
          // stroke-width="1"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export default ImageLogo
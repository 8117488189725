"use client"

import React from 'react'
import { Layout } from 'antd'

const { Footer } = Layout

type Props = {}

const ComponentFooter = (props: Props) => {
  return (
    <Footer className='footer'>Developed by SCGINTL 2023</Footer>
  )
}

export default ComponentFooter
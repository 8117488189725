"use client"

import React from 'react'
import { Button, Divider, Form, Input, Space, Typography } from 'antd'
import type { FormInstance } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { signIn, getCsrfToken } from 'next-auth/react'
import toast, { Toaster } from 'react-hot-toast'

import ImageLogo from './ImageLogo'
import { FieldType } from '@/models/pha.model'

const { Text } = Typography

const SubmitButton = ({ form }: { form: FormInstance }) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false)

  // Watch all values
  const values = Form.useWatch([], form)

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      },
    )
  }, [values, form])

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} className="color-primary">
      Login
    </Button>
  )
}

const ViewLogin = ({ csrfToken }: any) => {
  const [form] = Form.useForm()

  const [openCredential, setOpenCredential] = React.useState<boolean>(false)

  const handleSigninAD = async () => {
    // await signIn("azure-ad", { callbackUrl: `${window.location.origin}` })
    await signIn("azure-ad", { callbackUrl: `${window.location.origin}/user` })
  }

  const handleSigninCredential = async () => {
    await setOpenCredential(!openCredential)
  }

  const onFinishForm = async (values: any) => {
    const res = await signIn('credentials', {
      redirect: false,
      email: values.email,
      password: values.password,
      callbackUrl: `${window.location.origin}/user`,
      // callbackUrl: `${window.location.origin}`,
    })

    if (res?.error) toast.error(`Failed: ${res.error}`)

    if (res?.url) {
      window.location.reload()
    }
  }

  const onFailedForm = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className='login'>
      <Divider orientation="left">Please Login before using this sytem</Divider>

      <Space wrap align="center">
        <Button type="primary" size='large' block onClick={handleSigninCredential} className="color-primary">
          Login with Tester
        </Button>

        <Button type="primary" danger size='large' block onClick={handleSigninAD}>
          Login with SCG
        </Button>
      </Space>

      {openCredential && <div className='form-login-block'>
        <Space align="center" direction="vertical">
          <Text strong>Form Login with Tester</Text>

          <ImageLogo />
        </Space>

        <Form
          form={form}
          name="form-login"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ csrfToken }}
          onFinish={onFinishForm}
          onFinishFailed={onFailedForm}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            name="csrfToken"
          >
            <Input type='hidden' />
          </Form.Item>

          <Form.Item<FieldType>
            hasFeedback
            validateTrigger="onBlur"
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email', message: 'Please input your Email!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="email@example.com" size='large' />
          </Form.Item>

          <Form.Item<FieldType>
            hasFeedback
            validateTrigger="onBlur"
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" size='large' />
          </Form.Item>

          <Text strong>{`${process.env.NEXT_PUBLIC_NODE_ENV}`}</Text>

          <Form.Item>
            <SubmitButton form={form} />
          </Form.Item>
        </Form>
      </div>}

      <Toaster />
    </div>
  )
}

export async function getServerSideProps(context: any) {
  return {
    props: {
      csrfToken: await getCsrfToken(context),
    },
  }
}

export default ViewLogin
